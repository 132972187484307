// @flow
// (Copyright) Confluent, Inc.

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Meta = {
  content: string,
  name: string,
  ...
};

type Props = {
  description?: string,
  includeSwiftype?: boolean,
  lang?: string,
  meta?: Array<Meta>,
  title: string,
  ...
};

const Seo = ({
  description = '',
  includeSwiftype = false,
  lang = 'en',
  meta = [],
  title,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const pageMeta = useMemo(() => {
    const defaultMeta = [
      { name: `description`, content: metaDescription },
      { property: `og:title`, content: title },
      { property: `og:description`, content: metaDescription },
      { property: `og:type`, content: `website` },
      { name: `twitter:card`, content: `summary` },
      { name: `twitter:creator`, content: site.siteMetadata.author },
      { name: `twitter:title`, content: title },
      { name: `twitter:description`, content: metaDescription },
    ];

    const swiftypeMeta = includeSwiftype
      ? [
          { class: `swiftype`, name: `contentType`, 'data-type': `string`, content: 'connector' },
          {
            class: `swiftype`,
            name: `description`,
            'data-type': `string`,
            content: metaDescription,
          },
          { class: `swiftype`, name: `site-id`, 'data-type': `integer`, content: '2' },
          { class: `swiftype`, name: `title`, 'data-type': `string`, content: title },
        ]
      : [];

    return [...defaultMeta, ...meta, ...swiftypeMeta];
  }, [meta, includeSwiftype, metaDescription, title, site.siteMetadata.author]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={pageMeta}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <link href="https://fonts.gstatic.com" rel="preconnect" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&amp;display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default Seo;
