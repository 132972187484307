// @flow
// (Copyright) Confluent, Inc.

import * as React from 'react';
import styled from 'styled-components';
import { A, Icon, Logo } from '@confluent/core';

import URLS from '../constants/urls';
import footerBackgroundSvg from '../assets/svg/footer-background.svg';

const ProductBlock = () => (
  <ItemWrapper>
    <ListHeader href={URLS.PRODUCT}>Product</ListHeader>
    <Ul>
      <ListItem href={URLS.CONFLUENT_PLATFORM}>Confluent Platform</ListItem>
      <ListItem href={URLS.KSQL}>KSQL</ListItem>
      <ListItem href={URLS.SUBSCRIPTION}>Subscription</ListItem>
      <ListItem href={URLS.PROFESSIONAL_SERVICES}>Professional Services</ListItem>
      <ListItem href={URLS.TRAINING}>Training</ListItem>
      <ListItem href={URLS.CUSTOMERS}>Customers</ListItem>
    </Ul>
  </ItemWrapper>
);

const CloudBlock = () => (
  <ItemWrapper>
    <ListHeader href={URLS.CLOUD}>Cloud</ListHeader>
    <Ul>
      <ListItem href={URLS.CONFLUENT_CLOUD}>Confluent Cloud</ListItem>
      <ListItem href={URLS.SUPPORT}>Support</ListItem>
      <ListItem href={URLS.SIGNUP}>Sign Up</ListItem>
      <ListItem href={URLS.LOGIN}>Log In</ListItem>
    </Ul>
  </ItemWrapper>
);

const SolutionsBlock = () => (
  <ItemWrapper>
    <ListHeader href={URLS.INDUSTRY_SOLUTIONS}>Solutions</ListHeader>
    <Ul>
      <ListItem href={URLS.INDUSTRY_SOLUTIONS}>Industry Solutions</ListItem>
      <ListItem href={URLS.MICOSERVICES}>Microservices</ListItem>
      <ListItem href={URLS.INTERNET_OF_THINGS}>Internet of Things</ListItem>
      <ListItem href={URLS.FINANCIAL_SERVICES}>Financial Services</ListItem>
      <ListItem href={URLS.FRAUD_DETECTION}>Fraud Detection</ListItem>
      <ListItem href={URLS.CUSTOMER_360}>Customer 360</ListItem>
      <ListItem href={URLS.AZURE_HYBRID_STREAMING}>Azure Hybrid Streaming</ListItem>
    </Ul>
  </ItemWrapper>
);

const DevelopersBlock = () => (
  <ItemWrapper>
    <ListHeader href={URLS.DEVELOPERS}>Developers</ListHeader>
    <Ul>
      <ListItem href={URLS.WHAT_IS_KAFKA}>What is Kafka?</ListItem>
      <ListItem href={URLS.RESOURCES}>Resources</ListItem>
      <ListItem href={URLS.EVENTS}>Events</ListItem>
      <ListItem href={URLS.ONLINE_TALKS}>Online Talks</ListItem>
      <ListItem href={URLS.MEETUPS}>Meetups</ListItem>
      <ListItem href={URLS.KAFKA_SUMMIT}>Kafka Summit</ListItem>
      <ListItem href={URLS.KAFKA_TUTORIALS}>Kafka Tutorials</ListItem>
      <ListItem href={URLS.DOCS}>Docs</ListItem>
      <ListItem href={URLS.BLOG}>Blog</ListItem>
    </Ul>
  </ItemWrapper>
);

const AboutBlock = () => (
  <ItemWrapper>
    <ListHeader href={URLS.ABOUT}>About</ListHeader>
    <Ul>
      <ListItem href={URLS.COMPANY}>Company</ListItem>
      <ListItem href={URLS.CAREERS}>Careers</ListItem>
      <ListItem href={URLS.PARTNERS}>Partners</ListItem>
      <ListItem href={URLS.NEWS}>News</ListItem>
      <ListItem href={URLS.EVENTS}>Events</ListItem>
      <ListItem href={URLS.CONTACT}>Contact</ListItem>
    </Ul>
  </ItemWrapper>
);

const Footer = () => (
  <OverWrapper>
    <Wrapper>
      <StyledLogo variant="light" />
      <LeftWrapper>
        <LinksWrapper>
          <ProductBlock />
          <CloudBlock />
          <SolutionsBlock />
          <DevelopersBlock />
          <AboutBlock />
        </LinksWrapper>
        <Block>
          <IconsWrapper>
            <A href={URLS.TWITTER} target="_blank">
              <Icon name="socialTwitter" size={24} />
            </A>
            <A href={URLS.LINKEDIN} target="_blank">
              <Icon name="socialLinkedin" size={24} />
            </A>
            <A href={URLS.GITHUB} target="_blank">
              <Icon name="socialGithub" size={24} />
            </A>
            <A href={URLS.SLIDESHARE} target="_blank">
              <Icon name="socialSlideshare" size={24} />
            </A>
            <A href={URLS.YOUTUBE} target="_blank">
              <Icon name="socialYoutube" size={24} />
            </A>
            <A href={URLS.INSTAGRAM} target="_blank">
              <Icon name="socialInstagram" size={24} />
            </A>
            <A href={URLS.FACEBOOK} target="_blank">
              <Icon name="socialFacebook" size={24} />
            </A>
          </IconsWrapper>
          <Link css="vertical-align: sub;" href={URLS.VERIFICATION_GUIDE} underline={true}>
            Verification Guide for Confluent Technical Partners only
          </Link>
        </Block>
        <Hr />
        <Text>Copyright © Confluent, Inc. 2014-{new Date().getFullYear()}.</Text>{' '}
        <Link href={URLS.PRIVACY_POLICY}>Privacy Policy</Link> <Delimiter />
        <Link href={URLS.TERMS_OF_USE}>Terms &amp; Conditions</Link> <Delimiter />
        <Link href={URLS.MODERN_SLAVERY_POLICY}>Modern Slavery Policy</Link> <Delimiter />
        <Link
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.OneTrust.ToggleInfoDisplay();
          }}
        >
          Cookie Settings
        </Link>{' '}
        <Delimiter />
        <Text css="font-weight: 300">
          Apache, Apache Kafka, Kafka, and associated open source project names are trademarks of
          the Apache Software Foundation
        </Text>
      </LeftWrapper>
    </Wrapper>
  </OverWrapper>
);

export default Footer;

const LeftWrapper = styled.div`
  max-width: 940px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const ItemWrapper = styled.div``;

const ListItem = (props) => (
  <li>
    <Link {...props} />
  </li>
);

const IconsWrapper = styled.div`
  float: right;
  height: 24px;

  a {
    display: inline-block;
    margin-left: 12px;
    &:first-child {
      margin-left: 0;
    }

    ${Icon} {
      background: var(--DIM_DARK);

      path {
        fill: var(--WHITE);
      }
    }
  }
`;

const OverWrapper = styled.footer`
  background: var(--DIM_DARK)
    url('data:image/svg+xml;utf8,${encodeURIComponent(footerBackgroundSvg)}') no-repeat bottom -250px
    right -400px;
  background-size: 800px 800px;
`;

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 80px 50px 80px;

  @media (max-width: 690px) {
    padding-top: 40px;

    ${LinksWrapper} {
      display: none;
    }

    ${IconsWrapper} {
      float: none;
      margin: 30px auto 50px auto;
      text-align: center;
    }
  }
`;

const UlPlain = styled.ul`
  margin: 0;
  padding: 0;
  & > li {
    margin: 0 0 12px 0;
    list-style: none;
  }
`;

const UlLine = styled.ul`
  position: relative;
  margin: 2px 0 0 0;
  padding: 0 0 0 14px;
  & > li {
    margin: 0 0 12px 0;
    list-style: none;
  }
`;

const VerticalLine = styled.div`
  width: 2px;
  background: var(--PRIMARY_LIGHTER);
  opacity: 0.48;
  position: absolute;
  top: -10px;
  bottom: 4px;
  left: 0;
`;

const Ul = ({ children, withLine = false }: { children: React.Node, withLine?: boolean, ... }) =>
  withLine ? (
    <UlLine>
      <VerticalLine />
      {children}
    </UlLine>
  ) : (
    <UlPlain>{children}</UlPlain>
  );

const ListHeader = styled.a`
  color: var(--WHITE);
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: var(--WHITE);
  opacity: 0.48;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  font-weight: 500;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`;

const Block = styled.div`
  ::after {
    display: table;
    content: '';
    clear: both;
  }
`;

const Text = styled.span`
  opacity: 0.48;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
  color: var(--WHITE);
`;

const Delimiter = styled.span`
  color: var(--WHITE);
  margin: 0 4px 0 2px;

  ::before {
    content: '|';
  }
`;

const Hr = styled.div`
  height: 1px;
  margin: 10px 0;
  opacity: 0.48;
  background-color: var(--WHITE);
`;

const StyledLogo = styled(Logo)`
  float: right;
  width: 148px;
  height: 24px;
  margin-left: 80px;

  @media (max-width: 1000px) {
    float: none;
    display: block;
    margin: 0 auto 40px auto;
  }
`;
