// @flow
// (Copyright) Confluent, Inc.

import React, { type ElementConfig, type Node, useState } from 'react';
import styled from 'styled-components';
import { A, Icon, InSVG, Logo } from '@confluent/core';

import close from '../assets/svg/close.svg';
import hamburger from '../assets/svg/mobile-menu.svg';
import confluentIcon from '../assets/svg/confluent-icon.svg';
import URLS, { cloudLinks, developerLinks, type LinkData, productLinks } from '../constants/urls';

type HeaderProps = {
  siteTitle?: string,
};

type ListItemProps = {
  ...ElementConfig<'a'>,
  menuItems?: LinkData[],
  ...
};

export const HEADER_BREAKPOINT = 950;
export const HEADER_LOGO_BREAKPOINT = 560;

const visibleOnDesktop = (breakpoint?: number = HEADER_BREAKPOINT) => `
@media (max-width: ${breakpoint}px) {
  display: none;
}`;

const visibleOnMobile = (breakpoint?: number = HEADER_BREAKPOINT) => `
@media (min-width: ${breakpoint + 1}px) {
  display: none;
}`;

const HeaderBackground = styled.header`
  background: var(--WHITE);
`;

const HeaderContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  padding: 33px 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LogoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const LogoDesktop = styled(Logo)`
  width: 195px;
  height: 37px;

  ${visibleOnDesktop(HEADER_LOGO_BREAKPOINT)}
`;

const LogoMobile = styled(InSVG)`
  width: 40px;
  height: 40px;

  ${visibleOnMobile(HEADER_LOGO_BREAKPOINT)}
`;

const DesktopNav = styled.nav`
  ${visibleOnDesktop()}
`;

const MobileNav = styled.nav`
  a {
    color: var(--DIM_DARKER);
  }

  ${visibleOnMobile()}
`;

const NavList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MobileNavList = styled(NavList)`
  margin: 0 38px 18px;

  a {
    display: inline-block;
    padding: 10px;
    font-weight: 500;
  }
`;

const MobileHamburger = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  padding: 5px;

  path {
    fill: var(--PRIMARY_DARK);
  }

  ${visibleOnMobile()}
`;

const Link = styled.a`
  display: inline-block;
  margin: 0 16px;
  border: solid transparent;
  border-width: 2px 0;
  padding: 5px;
  color: var(--PRIMARY_DARK);
  cursor: pointer;

  :hover {
    border-bottom-color: var(--SECONDARY);
    color: var(--PRIMARY_DARKEST);
  }
`;

const NavPopoverWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 16px;
  border-radius: 4px;
  background-color: var(--WHITE);
  color: var(--PRIMARY_LIGHT);
  white-space: nowrap;
`;

const NavPopoverContainer = styled.li`
  display: inline-block;
  position: relative;
  padding: 16px 0;

  ${NavPopoverWrapper} {
    display: none;
  }

  :hover {
    ${NavPopoverWrapper} {
      display: block;
    }

    ${Link} {
      border-bottom-color: var(--SECONDARY);
    }
  }
`;

const PopoverLink = styled.a`
  display: block;
  padding: 9px 25px 9px 16px;
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }
`;

const MobileNavSectionWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  padding: 18px 24px;
  background-color: var(--WHITE);

  > a {
    font-size: 18px;
  }
`;

const Chevron = styled(Icon)`
  path {
    fill: var(--DIM_DARKER);
    fill-opacity: var(--OPACITY_32);
  }
`;

const Divider = styled.div`
  margin: 0 24px;
  height: 2px;
  background-color: var(--PRIMARY_LIGHTEST);
`;

const PopoverListItem = ({ href, children }: { children?: Node, href: string, ... }) => (
  <li>
    <PopoverLink href={href}>{children}</PopoverLink>
  </li>
);

const NavPopover = ({ menuItems }: { menuItems: LinkData[], ... }) => (
  <NavPopoverWrapper>
    <NavList>
      {menuItems.map((item) => (
        <PopoverListItem href={item.url} key={item.url}>
          {item.text}
        </PopoverListItem>
      ))}
    </NavList>
  </NavPopoverWrapper>
);

const ListItem = ({ children, href, menuItems }: ListItemProps) => (
  <>
    {menuItems ? (
      <NavPopoverContainer>
        <Link href={href}>{children}</Link>
        <NavPopover menuItems={menuItems} />
      </NavPopoverContainer>
    ) : (
      <Link href={href}>{children}</Link>
    )}
  </>
);

const DesktopHeaderNav = () => (
  <DesktopNav>
    <NavList>
      <ListItem href={URLS.HUB_HOME}>Confluent Hub Home</ListItem>
      <ListItem href={URLS.CLOUD} menuItems={cloudLinks}>
        Confluent Cloud
      </ListItem>
      <ListItem href={URLS.PRODUCT} menuItems={productLinks}>
        Product
      </ListItem>
      <ListItem href={URLS.DEVELOPERS} menuItems={developerLinks}>
        Developers
      </ListItem>
      <ListItem href={URLS.BLOG}>Blog</ListItem>
      <ListItem href={URLS.DOC_HOME}>Docs</ListItem>
    </NavList>
  </DesktopNav>
);

const MobileNavSection = ({ children, href, menuItems }: ListItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperProps = menuItems
    ? {
        onClick: () => setIsOpen(!isOpen),
        type: 'button',
      }
    : {
        as: 'div',
      };

  return (
    <>
      <MobileNavSectionWrapper {...wrapperProps}>
        <A href={href} onClick={(e) => e.stopPropagation()}>
          {children}
        </A>
        {menuItems &&
          (isOpen ? (
            <Chevron key="up" name="chevronUp" size={16} />
          ) : (
            <Chevron key="down" name="chevronDown" size={16} />
          ))}
      </MobileNavSectionWrapper>
      {menuItems && isOpen && (
        <MobileNavList>
          {menuItems.map((item) => (
            <li key={item.url}>
              <A href={item.url}>{item.text}</A>
            </li>
          ))}
        </MobileNavList>
      )}
    </>
  );
};

const HeaderMobileNav = () => (
  <MobileNav>
    <MobileNavSection href={URLS.HUB_HOME}>Confluent Hub Home</MobileNavSection>
    <Divider />
    <MobileNavSection href={URLS.CLOUD} menuItems={cloudLinks}>
      Confluent Cloud
    </MobileNavSection>
    <Divider />
    <MobileNavSection href={URLS.PRODUCT} menuItems={productLinks}>
      Product
    </MobileNavSection>
    <Divider />
    <MobileNavSection href={URLS.DEVELOPERS} menuItems={developerLinks}>
      Developers
    </MobileNavSection>
    <Divider />
    <MobileNavSection href={URLS.BLOG}>Blog</MobileNavSection>
    <Divider />
    <MobileNavSection href={URLS.DOC_HOME}>Docs</MobileNavSection>
  </MobileNav>
);

const Header = ({ siteTitle = 'Confluent Hub' }: HeaderProps) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <>
      <HeaderBackground>
        <HeaderContainer>
          <LogoLink href={URLS.HOMEPAGE}>
            <LogoMobile aria-label={siteTitle} src={confluentIcon} />
            <LogoDesktop aria-label={siteTitle} />
          </LogoLink>
          <DesktopHeaderNav />
          <MobileHamburger onClick={() => setShowMobileNav(!showMobileNav)} type="button">
            {showMobileNav ? (
              <InSVG height={24} key="close" src={close} width={24} />
            ) : (
              <InSVG height={24} key="hamburger" src={hamburger} width={24} />
            )}
          </MobileHamburger>
        </HeaderContainer>
      </HeaderBackground>
      {showMobileNav && <HeaderMobileNav />}
    </>
  );
};

export default Header;
