// @flow
// (Copyright) Confluent, Inc.

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GlobalStyles, createTheme } from '@confluent/core';

import Header from './Header';
import Footer from './Footer';

type Props = { children: React.Node, ... };

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
`;

const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={createTheme()}>
      <LayoutWrapper>
        <Header siteTitle={data.site.siteMetadata.title} />
        <GlobalStyles />
        <GlobalStylesHub />
        <main>{children}</main>
        <Footer />
      </LayoutWrapper>
    </ThemeProvider>
  );
};

export default Layout;

const GlobalStylesHub = createGlobalStyle`
  html, body {
    background: var(--WHITE);
  }

  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
`;
